import type { AdminMetalMachineCreateBody, MetalStateHistory } from "@warrenio/api-spec/spec.oats.gen";
import { Controller, useForm } from "react-hook-form";
import { keys, pick } from "remeda";
import { controllerProps, formPropsToFormFieldProps } from "../../../components/forms/ariaController.tsx";
import { FormField } from "../../../components/forms/FormField.tsx";
import { useStandardMutation } from "../../../modules/api/useStandardMutation.ts";
import type { LocationInputs } from "../../../modules/location/LocationField.tsx";
import { useLocationsForType } from "../../../modules/location/query.ts";
import { changedFields } from "../../../utils/changedFields.ts";
import { AdminLocationField } from "../../AdminLocationField.tsx";
import { AFTextField } from "../../form/AdminFormFields.tsx";
import { WAdminModalCrudForm } from "../../form/AdminModalContent.tsx";
import { ATextArea } from "../../form/Fields.tsx";
import { UuidField } from "../../form/UuidField.tsx";
import { AdminMetalOsSelect } from "../AdminMetalOsSelect.tsx";
import { ASpecSelect } from "../specs/SpecSelect.tsx";
import { machineItemFields } from "./machineItemFields.ts";
import { machineCreateMutation, machineUpdateMutation } from "./machinesQuery.ts";
import type { AdminMachineWithOs } from "./machinesWithOs.ts";

export function MachineItemModal({ item }: { item?: AdminMachineWithOs | undefined }) {
    interface Inputs extends AdminMetalMachineCreateBody, LocationInputs {
        os_id: MetalStateHistory["os_id"];
    }

    //#region Hooks
    const locations = useLocationsForType("bare_metal");

    const createFields: Inputs = {
        location: locations.defaultLocation,

        label: "",
        os_id: null,
        admin_notes: "",
        ssh_credentials: null,
        // NB: TypeScript hack, there's a validator attached to this field to make sure it's set before submit
        spec_uuid: undefined!,
        mac_addresses: [],
        ip_public_v4: "",
        ip_public_v6: null,
    };

    /** Existing item converted to form fields */
    const updateFields = item
        ? {
              ...item,
              // NB: This field is in a different path in the read vs. write API
              spec_uuid: item.spec.uuid,
          }
        : undefined;

    const form = useForm<Inputs>({
        defaultValues: updateFields ? pick(updateFields, keys(createFields)) : createFields,
    });
    const { control } = form;

    const createMutation = useStandardMutation(machineCreateMutation);
    const updateMutation = useStandardMutation(machineUpdateMutation);

    //#endregion

    async function onSubmit({ location, ...body }: Inputs) {
        if (updateFields) {
            const changed = changedFields(updateFields, body);
            if (changed) {
                await updateMutation.mutateAsync({
                    body: changed,
                    location,
                    uuid: updateFields.uuid,
                });
            }
        } else {
            await createMutation.mutateAsync({ body, location });
        }
    }

    const isEdit = !!item;

    return (
        <WAdminModalCrudForm entityType="machine" isUpdate={isEdit} form={form} onSubmit={onSubmit}>
            <UuidField value={item?.uuid} />

            <AdminLocationField locations={locations} isUpdate={isEdit} />

            <AFTextField control={control} name="label" isRequired config={machineItemFields.label} />

            <Controller
                control={control}
                name="os_id"
                render={(p) => (
                    <FormField wide label={machineItemFields.os.title} {...formPropsToFormFieldProps(p)}>
                        <AdminMetalOsSelect
                            defaultValueKey={p.field.value}
                            onChange={(item) => p.field.onChange(item?.os_id ?? null)}
                        />
                    </FormField>
                )}
            />

            <Controller
                control={control}
                name="spec_uuid"
                {...controllerProps({ isRequired: true })}
                render={(p) => (
                    <FormField
                        wide
                        isRequired
                        label={machineItemFields.spec_title.title}
                        {...formPropsToFormFieldProps(p)}
                    >
                        <ASpecSelect
                            getKey={(i) => i.uuid}
                            defaultValueKey={p.field.value}
                            onChange={(spec) => {
                                p.field.onChange(spec.uuid);
                            }}
                        />
                    </FormField>
                )}
            />

            <AFTextField control={control} name="ip_public_v4" isRequired config={machineItemFields.ip_public_v4} />
            <AFTextField control={control} name="ip_public_v6" nullable config={machineItemFields.ip_public_v6} />

            <AFTextField control={control} name="admin_notes" config={machineItemFields.admin_notes}>
                <ATextArea />
            </AFTextField>

            <AFTextField control={control} name="ssh_credentials" nullable config={machineItemFields.ssh_credentials}>
                <ATextArea />
            </AFTextField>
        </WAdminModalCrudForm>
    );
}
