import type { AdminMetalLeaseCreateBody, MetalStatus } from "@warrenio/api-spec/spec.oats.gen";
import { TextField } from "react-aria-components";
import { Controller, useForm } from "react-hook-form";
import { keys, pick } from "remeda";
import {
    controllerProps,
    formPropsToAriaProps,
    formPropsToFormFieldProps,
} from "../../../components/forms/ariaController.tsx";
import { FormField } from "../../../components/forms/FormField.tsx";
import { useStandardMutation } from "../../../modules/api/useStandardMutation.ts";
import { statusItems } from "../../../modules/metal/status/StatusLabels.tsx";
import { changedFields } from "../../../utils/changedFields.ts";
import { AFTextField } from "../../form/AdminFormFields.tsx";
import { WAdminModalForm } from "../../form/AdminModalContent.tsx";
import { AInput } from "../../form/Fields.tsx";
import { UuidField } from "../../form/UuidField.tsx";
import { AdminMetalOsSelect } from "../AdminMetalOsSelect.tsx";
import { leaseFields } from "./leaseFields.tsx";
import { leaseCreateMutation, leaseUpdateMutation, machineUpdateMutation } from "./machinesQuery.ts";
import type { AdminMachineWithOs } from "./machinesWithOs.ts";
import { StatusSelector } from "./StatusSelector.tsx";

function isLeasedStatus(status: MetalStatus) {
    return status === "in_use" || status === "pending";
}

export function LeaseModal({ machine }: { machine: AdminMachineWithOs }) {
    interface Inputs extends AdminMetalLeaseCreateBody {
        status: MetalStatus;
    }

    //#region Hooks
    const { location } = machine;

    const leaseCreateDefaults = {
        billing_account_id: undefined!,
        display_name: "Custom machine",
        machine_uuid: machine.uuid,
        requested_os_id: machine.os_id!,
    } satisfies Omit<Inputs, "status">;

    const leaseUpdateFields = machine.lease
        ? {
              ...machine.lease,

              machine_uuid: machine.uuid,
              // Billing account is guaranteed to be set when a lease is present
              billing_account_id: machine.billing_account_id!,
          }
        : undefined;

    const leaseDefaults = leaseUpdateFields ? pick(leaseUpdateFields, keys(leaseCreateDefaults)) : leaseCreateDefaults;

    const form = useForm<Inputs>({
        defaultValues: {
            ...leaseDefaults,

            status: machine.status,
        },
    });
    const { control, watch } = form;

    const machineUpdate = useStandardMutation(machineUpdateMutation);
    const leaseCreate = useStandardMutation(leaseCreateMutation);
    const leaseUpdate = useStandardMutation(leaseUpdateMutation);

    async function onSubmit({ status, ...body }: Inputs) {
        if (isLeasedStatus(status)) {
            if (leaseUpdateFields) {
                const changed = changedFields(leaseUpdateFields, body);
                if (changed) {
                    await leaseUpdate.mutateAsync({
                        body: changed,
                        uuid: leaseUpdateFields.uuid,
                        location,
                    });
                }
            } else {
                // XXX: Lease is created in either "in_use" or "pending" status automatically,
                // but the machine mutation below could overwrite it.
                await leaseCreate.mutateAsync({ body, location });
            }
        }

        if (status !== machine.status) {
            await machineUpdate.mutateAsync({ body: { status }, uuid: machine.uuid, location });
        }
    }
    //#endregion

    const isLeaseEdit = !!leaseUpdateFields;
    const canChangeBillingAccount = !isLeaseEdit;

    const leaseForm = (
        <>
            <AFTextField control={control} name="display_name" isRequired config={leaseFields.display_name} />
            <Controller
                control={control}
                name="requested_os_id"
                {...controllerProps({ isRequired: true })}
                render={(p) => (
                    <FormField wide label={leaseFields.requested_os_id.title} {...formPropsToFormFieldProps(p)}>
                        <AdminMetalOsSelect
                            defaultValueKey={p.field.value}
                            onChange={(item) => p.field.onChange(item?.os_id)}
                        />
                    </FormField>
                )}
            />
            {/* TODO: Better BA selector */}
            <Controller
                control={control}
                name="billing_account_id"
                disabled={!canChangeBillingAccount}
                render={(p) => (
                    <FormField wide label="Billing account ID" {...formPropsToFormFieldProps(p)}>
                        <TextField
                            {...formPropsToAriaProps(p)}
                            aria-label="Billing account ID"
                            pattern="\d+"
                            onChange={(v) => p.field.onChange(Number(v))}
                            value={String(p.field.value ?? "")}
                        >
                            <AInput />
                        </TextField>
                    </FormField>
                )}
            />
        </>
    );

    return (
        <WAdminModalForm form={form} onSubmit={onSubmit} label="Save" title="Edit machine status">
            <UuidField value={leaseUpdateFields?.uuid} />

            <Controller
                control={control}
                name="status"
                {...controllerProps({ isRequired: true })}
                render={(p) => (
                    <StatusSelector items={statusItems} value={p.field.value} onChange={(v) => p.field.onChange(v)} />
                )}
            />

            {isLeasedStatus(watch("status")) && (
                <>
                    <h2
                        // XXX: How to get proper padding here without hardcoding...
                        className="pl-4 pt-1"
                    >
                        Lease details
                    </h2>
                    {leaseUpdateFields && (
                        <>
                            <FormField wide label="User ID">
                                {leaseUpdateFields.user_id}
                            </FormField>
                            <FormField wide label="Created at">
                                {leaseUpdateFields.created_at}
                            </FormField>
                        </>
                    )}
                    {leaseForm}
                </>
            )}
        </WAdminModalForm>
    );
}
